import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Home.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import logo from '../assets/logo.png'
import CairoMorning from '../assets/Cairo morning/ABB1D61E-AFE1-43FA-823A-FC9555372F9B.jpeg'
// import { type CarouselApi } from "@/components/ui/carousel"
import CityMRI from "../assets/City MRI/IMG_1910.jpg"
import Displacement from "../assets/Displacement/IMG_0744.jpg"
import TheFakeSaint from '../assets/The Fake Saint/IMG_3366.jpeg'
import LadyTransformation from "../assets/The bird lady transformation/IMG_2195.jpeg"
import FragmentedCity from "../assets/Fragmented City/IMG_0425.jpeg" 
const Home: React.FC = () => {
  useEffect(() => {
    document.title = 'Home - Amani Moussa';
  }, []);

  const navigate = useNavigate();

  const handleImageClick = (projectId: number) => {
    navigate(`/projects/${projectId}`);
  };

  return (
    <div className="home-container">
      <div className="home-content">
        <h1>            <img className='nav-image' src={logo} alt='amanimoussa'height={50} />Amani Moussa</h1>
        {/* <p>Welcome to my blog!</p> */}
      </div>
      <Carousel
        showArrows={true}
        autoPlay={true}
        infiniteLoop={true}
        showStatus={false}
        showThumbs={true}
        interval={3000}
        swipeable={true} 
        emulateTouch={true}
      >
        <div onClick={() => handleImageClick(1)}>
          <h1>The Morning Battle</h1>
          <img className='home-image' src={CairoMorning} alt="Slide 1" />
        </div>
        <div onClick={() => handleImageClick(2)}>
        <h1>City MRI</h1>
          <img className='home-image' src={CityMRI} alt="Slide 2" />
        </div>
        <div onClick={() => handleImageClick(3)}>
        <h1>Displacement</h1>
          <img src={Displacement} alt="Slide 3" />
        </div>
        <div onClick={() => handleImageClick(4)}>
        <h1>The Bird Lady Transformation</h1>
          <img className='home-image' src={LadyTransformation} alt="Slide 4" />
        </div>
        <div onClick={() => handleImageClick(5)}>
        <h1>The Fake Saint</h1>
          <img  className='home-image' src={TheFakeSaint} alt="Slide 5" />
        </div>
        <div onClick={() => handleImageClick(6)}>
        <h1>Fragmented City</h1>
          <img className='home-image' src={FragmentedCity} alt="Slide 6" />
        </div>
        <div onClick={() => handleImageClick(7)}>
        <h1>The City Top View</h1>
          <img src={logo} alt="Slide 7" />
        </div>
        <div onClick={() => handleImageClick(8)}>
        <h1>Crowd</h1>
          <img className='home-image' src={logo} alt="Slide 8" />
        </div>
      
      </Carousel>
    </div>
  );
};

export default Home;
