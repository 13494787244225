import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/NavBar.css';
import logo from '../assets/new logo.jpg';
import { FaBars, FaTimes } from 'react-icons/fa'; // Using react-icons for the toggle icon

const NavBar: React.FC = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(true); // Set default state to collapsed

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <nav className={`navbar`}>
      {/* <button className="navbar-toggle" onClick={toggleNavbar}>
        {collapsed ? <FaBars /> : <FaTimes />}
      </button> */}
      <ul>
        <li>
          <Link to="/">
            <img className='nav-image' src={logo} alt='amanimoussa'  />
            <span className="highlighted-text">Amani Moussa</span>
          </Link>
        </li>
        <li onMouseEnter={toggleDropdown} onMouseLeave={closeDropdown}>
          <span className="dropdown-toggle">Projects</span>
          {dropdownOpen && (
            <ul className="dropdown-menu">
              <li>
                <Link to="/projects/1" onClick={closeDropdown}>The Morning Battle</Link>
              </li>
              <li>
                <Link to="/projects/2" onClick={closeDropdown}>City MRI</Link>
              </li>
              <li>
                <Link to="/projects/3" onClick={closeDropdown}>Displacement</Link>
              </li>
              <li>
                <Link to="/projects/4" onClick={closeDropdown}>The Bird Lady Transformation</Link>
              </li>
              <li>
                <Link to="/projects/5" onClick={closeDropdown}>The Fake Saint</Link>
              </li>
              <li>
                <Link to="/projects/6" onClick={closeDropdown}>Fragmented City</Link>
              </li>
              <li>
                <Link to="/projects/7" onClick={closeDropdown}>The City Top View</Link>
              </li>
              <li>
                <Link to="/projects/8" onClick={closeDropdown}>Crowd</Link>
              </li>
            </ul>
          )}
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
