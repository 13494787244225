import React,{useEffect} from 'react';
import logo from '../assets/logo.png'
import '../css/Projects.css'
const Projects: React.FC = () => {
    useEffect(() => {
        document.title = 'Projects - Amani Moussa';
      }, []); 
      
  return (
    <div className='project-container'>
      <h1>The Morning Battle (2024)</h1>
      <p>
      while the word morning may bring to mind a certain sense of calm or quiet, as people wake up anticipating
      the day ahead and looking forward to their morning rituals (drinking coffee or having breakfast), 
      for the residents of Cairo going about their day might entail a battle. To be able to traverse the city, 
      needs special qualifications, tactics and experience to manage and manoeuvre the many difficulties
       of Cairo’s urban reality. The series presents some of those challenges but also many of the ingenious survival
        skills Egyptian evolved and continue to invent to go through their ‘morning battle’.
     </p>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>

      <p>The boxing gloves tree, woodcut, collagraph and stencil on paper, 48*68 cm, 2024</p>
      <br></br>
      <h1>City MRI (2021/2022- )</h1>
      <p>
        In this project, I examine my surroundings the way MRI imaging might
      examine the human body. By zooming in on certain layers and elements,
      certain primary shapes start to emerge. This analytical breakdown of the city,
      creates “building blocks” that can appear random, but show the intricate
      composition that characterise the city as we now see it.
     </p>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <p></p>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <p></p>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <p>Ink drawing on paper, 25*17 cm, 2021-22 (each)</p>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <p>Ink drawing on paper, 25*17 cm, 2021 (each)</p>
      <br></br>

      <h1>Displacement (2020 - )</h1>
      <p>
      Through tracking the urban expansion of Cairo and Giza, and comparing the
      historical maps of both areas over the last 40 years, a drastic change has
      occurred turning large green areas (traditionally farmland) into grey concrete
      buildings and red brick constructions (mainly residential buildings and
      informal housing). A change that influenced the shape of the city and left a
      massive impact on us, ”the residents of Cairo”.
     </p>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <p>Relief and collagraph print on paper 50*70 cm, 2022 (each)</p>
      <br></br>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <p>Relief print on paper, 61*33 cm, 2021 (each)</p>
      <br></br>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <p>Woodcut printed on paper 20*25 cm, 2022 (each)</p>
      <br></br>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <p>Relief print on paper, 32*30 cm, 2020</p>
      <br></br>

      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <p>Mixed media on canvas 150*150 cm, 2023</p>
      <br></br>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <br></br>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <br></br>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <img src={logo} className='project_img' alt='The boxing gloves tree'></img>
      <p>Reimagining landscape Collage (from old relief prints) on paper, 14*18 cm, ,2022 (each)</p>
      <br></br>
      
      <h1>The Bird Lady Transformation (2020 - 2021)</h1>
      <p>
      Hypothetically giving the bird lady (Egyptian statue dated to the Predynastic
      period, Naqada IIa c.3500-3400 BC. Excavated at the village of Ma'mariya in
      1907) the opportunity to pass through several realistic paths in our times,
      with all its demands and current pressures, and by making projections of
      personal experiences that I, or one of those around me, have been through.
      How will these modern experiences and ordeals affect her psychologically
      and physically?
     </p>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
     <br></br>
     <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
     <br></br>
     <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
     <br></br>
      <p>Mixed Media on paper, showing part of the evolution process of the bird lady (drawings and GIFS)</p>
      <br></br>

      <h1>The Fake Saint (2018 - 2020)</h1>
      <p>
      The title "Saint" is usually associated with all those who embodied idealised
      virtues during their life on earth and those who acted with "heroism". The
      men in our middle eastern societies, that support the individual freedom of a
      man from birth, and construct the gender roles, turn their status into a
      societal privilege. In such societies, it seems that societies are made for men,
      which give them a false sense of power through which they control others.
      This is manifested in parental authority, fraternal, and marital authority. He
      is the ideal. While enjoying his various rights, he drops the burden of his
      duties,one after the other. He becomes the only “divinely ordained” right.
     </p>
      <img src={logo} className='project_img' alt='Mixed Media on paper'></img>
      <p>Mixed Media on paper 29*21 cm, 2018</p>
      <img src={logo} className='project_img' alt='Mixed Media on paper'></img>
      <p>Woodcut and stencil on paper (limited edition of 3), 40*34 cm, 2021</p>
      <img src={logo} className='project_img' alt='Mixed Media on paper'></img>
      <p>Woodcut and stencil on paper, (limited edition of 5), 40*34 cm, 2020</p>
     <br></br>

     <h1>Fragmented City (2017 - 2020)</h1>
      <p>
      This series of drawings is inspired by my everyday life in Cairo. Spending
      hours in Cairo's hectic and crowded traffic wasn't my choice but this gave me
      the chance to document my everyday observations about the way the city is
      expanding and stretching toward the east and the west, doubling and tripling
      in size. This expansion across a network of vast highways gives many
      residents a sense of being scattered or rather an ever increasingly
      fragmented city.
     </p>
      <img src={logo} className='project_img' alt='Mixed Media on paper'></img>
      <p>Ink and pencil drawing on paper, 25*19 cm, 2019</p>
      <img src={logo} className='project_img' alt='Mixed Media on paper'></img>
      <p>Ink and pencil drawing on paper, 25*19 cm, 2017</p>
      <img src={logo} className='project_img' alt='Mixed Media on paper'></img>
      <p>Ink and pencil drawing on paper,25*19 cm, 2018</p>
      <img src={logo} className='project_img' alt='Mixed Media on paper'></img>
      <p>Ink and pencil drawing on paper, 25*19 cm, 2017</p>
     <br></br>

     <h1>The City Top View (2017) </h1>
      <p>
      “Set location on map”,” share your location with me”, and “send your location”.
        Using web mapping platforms on our mobile phones has been an action
        that we use on a daily basis, which is connected to my interest in how cities
        extend and expand to reach their final model of a city. Those maps with all
        their details showed me a different perspective on my work and
        understanding of how cities are “constructed” or mapped.
      </p>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      
     <br></br>
     <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      
     <br></br>
     <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      <img src={logo} className='mixed_media_on_paper' alt='Mixed Media on paper'></img>
      
     <br></br>
      <p>Ink and pencil drawing on paper, a set of 9, 15*15 cm, 2017 (each)</p>
      <br></br>
      <img src={logo} className='project_img' alt='Mixed Media on paper'></img>
      <p>Art Dubai, 2021</p>
      <br></br>
      <img src={logo} className='project_img' alt='Mixed Media on paper'></img>
      <p>Ink drawing on paper ,25*19cm, 2017</p>
      <br></br>

      <h1>Crowd (2013 - )</h1>
      <p>
      An ongoing project since 2013 from which subprojects have emerged. The
      project used different mediums to look at the way urban densities and
      elements are composed.
      </p>
      <img src={logo} className='project_img' alt='Mixed Media on paper'></img>
      <p>Digital printing and markers on paper, 70*50cm, 2019</p>
      <br></br>
      <img src={logo} className='project_img' alt='Mixed Media on paper'></img>
      <p>Woodcut and collagraph on paper, 60*45 cm, 2017</p>
      <br></br>
      <img src={logo} className='project_img' alt='Mixed Media on paper'></img>
      <p>Lost on details, Woodcut on paper,150*80cm, 2016</p>
      <br></br>
      <img src={logo} className='project_img' alt='Mixed Media on paper'></img>
      <p>Darb 1718, 2018, Cairo , Egypt</p>
      <br></br>
      
    </div>
  );
};

export default Projects;
