import React,{useEffect} from 'react';
import '../css/About.css'
import logo from '../assets/logo.png'
const About: React.FC = () => {
    useEffect(() => {
        document.title = 'About - Amani Moussa';
      }, []); 
      
  return (
    <div className='about-container'>
      {/* <h1 className='about-content'>Who is Amani Moussa?</h1> */}
      {/* <div className='container'>      
      <img className='about-content-img' src={logo}></img>
      </div> */}

      <br></br>
     <p>My work explores the tension between the weight of ideas and concepts and the limits and the opportunities of various mediums and forms.
       As a classically trained painter, I think a lot about how traditional mediums carry meaning beyond what is perceived as
        a historical genre or style. To me limits actually create possibilities of new ways of thinking about what can be
         generated and what can reveal unexpected visual and material meaning. I see that most clearly in my work in printmaking
          for example, where “accidents” constantly shape the way the final artwork emerges. And it is between the process and its
           development that my ideas and interest in my subject is in direct dialogue with the medium. I am also interested in the
            way digital mediums facilitate certain aspects of the work, and I find the fusion between conventional mediums and
             digital ones an exciting and fruitful merger.
      </p>
        <p>
        I believe a significant part of any artistic practice is the ability to observe and filter these observations through
         the artist’s own experience and artistic process. This has manifested itself in my work and my interest in landscape 
         as a genre, through which I process my own observation of my immediate surroundings and environment. That is the city 
         I live in and my relationship to it. I see the city as an indirect reflection of who we are and what we are. And the 
         different kinds of relationships we have with each other, is also manifested in how we relate to the city we live in. 
         As such themes of urban density, social conflict, destruction and fragmentation of urban fabrics,...etct have all been 
         part of an ongoing process of trying to understand my own relationship to the city and what I observe.
        </p>
        <p>
        This also extends to the idea of shifting lens or focus, where I also try to understand personal experiences and 
        interests through different genres and styles. For example, some of my work involves portraiture or figure drawing.
         This change of medium and focus involves a certain speculative turn, where my work is not just observational or
          a reflection but rather also a proposition to imagine things differently and to think about them in alternative 
          and new ways. This conversation between a certain experience and a certain interest in formal elements 
          (be it painting, printmaking, collage,...etc) and the process by which the artwork evolves is the core of my practice.
           I am interested in exploring this with all the possibilities a medium can afford and invite the audience to think
           and imagine along. 
        </p>
      
      <h2>Education:</h2>
      <p className='spacing'><b>2014/2015</b>&nbsp;&nbsp; Game Art Graphics Diploma, Information Technology Institute(ITI), Cairo, Egypt</p>
      <p className='spacing'><b>2008/2013</b> &nbsp;&nbsp;Bachelor of Fine Arts (Major: printmaking), Faculty of Fine Arts, University of Helwan, Cairo, Egypt</p>

      <h2>Grant/Awards:</h2>
      <p className='spacing'><b>2017/2019/2020</b> &nbsp;&nbsp;Production grant from the Egyptian Ministry of Culture for Artists</p>
      <p className='spacing'><b>2017</b>&nbsp;&nbsp;Salon prize, Upper Egypt International Salon, Luxor, Egypt</p>
      <p className='spacing'><b>2015</b>&nbsp;&nbsp;The Special Prize of the 25th Youth Salon, Cairo Opera House, Cairo,Egypt</p>
      <p className='spacing'><b>2015</b>&nbsp;&nbsp;The Commercial International Bank Prize, Upper Egypt International Salon, Luxor, Egypt</p>

      <h2>SOLO EXHIBITIONS:</h2>
      <p className='spacing'><b>2024</b>&nbsp;&nbsp;“Under Construction” (forthcoming) , Motion Art Gallery, Cairo, Egypt</p>
      <p className='spacing'><b>2022</b>&nbsp;&nbsp;“Displacement”, Motion Art Gallery, Cairo, Egypt</p>

      <h2>SELECTED GROUP EXHIBITIONS:</h2>
      <p className='spacing'><b>2024</b>&nbsp;&nbsp;“LINES OF INTIMACY”, Bibliothek Gallery, Cairo, Egypt</p>
      <p className='spacing'><b>2024</b>&nbsp;&nbsp;ART CAIRO “the fifth edition”, Grand Egyptian Museum, Cairo, Egypt</p>
      <p className='spacing'><b>2023</b>&nbsp;&nbsp;SPECTRUM group exhibition, Bibliothek Gallery, Cairo, Egypt</p>
      <p className='spacing'><b>2023</b>&nbsp;&nbsp;WOMAN, A MANIFESTO, TAM gallery, Cairo, Egypt</p>
      <p className='spacing'><b>2023</b>&nbsp;&nbsp;KAON with Art D’Égypte at Art Hub Gallery, Cairo, Egypt</p>
      <p className='spacing'><b>2016/2017/2018/2023</b>&nbsp;&nbsp;Agenda exhibition, Bibliotheca Alexandrina Conference Center (BACC), Alexandria, Egypt</p>
      <p className='spacing'><b>2023</b>&nbsp;&nbsp;“BLOG” exhibition, Access Art Space, Cairo, Egypt</p>
      <p className='spacing'><b>2023</b>&nbsp;&nbsp;ART CAIRO “the fourth edition”, Grand Egyptian Museum, Cairo, Egypt</p>
      <p className='spacing'><b>2022</b>&nbsp;&nbsp;UNLIMITED, The Meridian Center for Cultural Diplomacy (MCCD), Washington D.C., USA</p>
      <p className='spacing'><b>2022</b>&nbsp;&nbsp;”Imagining a Landscape”, Bibliothek Gallery, Cairo, Egypt</p>
      <p className='spacing'><b>2021</b>&nbsp;&nbsp;20th edition of the International Printmaking Biennial of Sarcelles,Paris, France</p>
      <p className='spacing'><b>2021</b>&nbsp;&nbsp;ART DUBAI Art Fair, Dubai, UAE</p>
      <p className='spacing'><b>2021</b>&nbsp;&nbsp;Tawr exhibition, Mono Gallery, Riyadh, KSA</p>
      <p className='spacing'><b>2021</b>&nbsp;&nbsp;Beirut Reprint, OpenVBach Gallery, Paris, France</p>
      <p className='spacing'><b>2020</b>&nbsp;&nbsp;Journée de l’Estampe (ACTUEL l’estampe contemporaine) à Saint Sulpice, Paris, France</p>
      <p className='spacing'><b>March 2020</b>&nbsp;&nbsp;Positive Space, TAM Gallery, Cairo, Egypt</p>
      <p className='spacing'><b>Feb 2020</b>&nbsp;&nbsp;Under 32, Mono Gallery, Riyadh, Saudi Arabia</p>
      <p className='spacing'><b>Nov 2019</b>&nbsp;&nbsp;The Third Binhai International Printmaking Camp exhibition, Tianjin Art Museum, China</p>
      <p className='spacing'><b>2016/2017/2018/2019/2020</b>&nbsp;&nbsp;CAIRO’s Art Fair, TAM (the arts mart) Gallery, Cairo, Egypt</p>
      <p className='spacing'><b>May 2019</b>&nbsp;&nbsp;HIT-utstillingen (Haugesund Internasjonale Tresnittfestival) (HIT), Haugesund Museum of Fine Art/Haugesund Billedgalleri, Norway</p>
      <p className='spacing'><b>2019</b>&nbsp;&nbsp;Win-win Cooperation/Belt and Road International Printmaking Exchange Project, Achievement Exhibition, The Forbidden Palace Museum, Beijing, China</p>
      <p className='spacing'><b>2019</b>&nbsp;&nbsp;Times and Echoes, MARAKEZ by Motion Art Gallery, Cairo, Egypt</p>
      <p className='spacing'><b>2018</b>&nbsp;&nbsp;Win-win Cooperation/Belt and Road International Printmaking Invitational Exhibition, Dunhuang International Convention Center, China</p>
      <p className='spacing'><b>2018</b>&nbsp;&nbsp;International Biennial Print Exhibit: R.O.C., National Taiwan Museum of Fine Arts (NTMOFA)</p>
      <p className='spacing'><b>2018</b>&nbsp;&nbsp;(le Printemps des artistes) at Darb 1718 in collaboration with the French Embassy in Egypt, Cairo, Egypt</p>
      <p className='spacing'><b>2018</b>&nbsp;&nbsp;Art Revolution Taipei, Art Fair, Taiwan</p>
      <p className='spacing'><b>2017</b>&nbsp;&nbsp;Tron, International printmaking works from residential artist camp in Binhai (Tianjin)</p>
      <p className='spacing'><b>2017</b>&nbsp;&nbsp;The Fifth International Upper Egypt Salon in Luxor (the salon prize)</p>
      <p className='spacing'><b>2017</b>&nbsp;&nbsp;Clyde & Co art award exhibition, the Gallery of Light,DUCTAC, UAE</p>
      <p className='spacing'><b>2014/2015/2017/2018</b>&nbsp;&nbsp;25th/26th/28th/29th/ Youth Salon, Palace of Arts, Cairo Opera House, Cairo, Egypt</p>
      <p className='spacing'><b>2013</b>&nbsp;&nbsp;Action painting: Hakawy International Festival, Cairo Opera House, Cairo, Egypt</p>



      <h2>RESIDENCIES:</h2>
      <p className='spacing'><b>2023</b>&nbsp;&nbsp;ALDAU International Art Festival, Hurghada, Egypt</p>
      <p className='spacing'><b>2023</b>&nbsp;&nbsp;ALDAU Annual Women Symposium, Hurghada, Egypt</p>
      <p className='spacing'><b>2020</b>&nbsp;&nbsp;Digital Residency Program, Goethe-Institut Cairo, Egypt</p>
      <p className='spacing'><b>2017</b>&nbsp;&nbsp;Tianjin Young Artist Community [YAC] Print Workshop, Tianjin, China</p>
      <p className='spacing'><b>2017</b>&nbsp;&nbsp;Guanlan Original Printmaking Base, Shenzhen, China</p>

      <h2>COLLECTIONS</h2>
      <p className='spacing'><b>2022</b>&nbsp;&nbsp;Freshfields Bruckhaus Deringer collection, London, UK</p>
      <p className='spacing'><b>2021</b>&nbsp;&nbsp;ART JAMEEL Contemporary Art Institution in Dubai</p>
      <p className='spacing'><b>2019</b>&nbsp;&nbsp;The Egyptian Ministry of Culture collection</p>
      <p className='spacing'><b>2018</b>&nbsp;&nbsp;National Taiwan Museum of Fine Arts, Taiwan</p>
      <p className='spacing'><b>2017</b>&nbsp;&nbsp;The Museum of Modern Art collection, Cairo, Egypt</p>
      <p className='spacing'><b>2017</b>&nbsp;&nbsp;Guanlan Printmaking Base collection, Shenzhen, China</p>
      <p className='spacing'><b>2017</b>&nbsp;&nbsp;YAC (young artists community) collection, Tianjin, China</p>
      <p className='spacing'><b>2015</b>&nbsp;&nbsp;Commercial International Bank Collection,Cairo,Egypt</p>

      
      <br></br>
      <br></br>
      <br></br>

      </div>
  )
};

export default About;
